import React from 'react';

import "../styles/singlePrice.scss"
import SinglePrice from './SinglePrice';

const Prices = props => (

    <div className="pricesContainer">
        {console.log(props.data)}
        {props.data.map((item, i) => <SinglePrice
            key={i}
            header={item.header}
            desc={item.desc}
            price={item.price}
        />)}
    </div>



)

export default Prices;