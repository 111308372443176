import React from 'react';
import "../styles/singlePrice.scss"



const SinglePrice = props => (

    <div className="singlePrice">
        <div className="descContainer">
            <h4 className="priceHeader">{props.header}</h4>
            <p className="priceDesc">{props.desc}
            </p>
        </div>
        <p className="price">{props.price}</p>
    </div>



)

export default SinglePrice;