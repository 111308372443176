import React from "react"
import Prices from "../../components/Prices"
import "../../styles/prices.scss"
import dataPrice from "../../data/price.json"

import SEO from "../../components/seo"

const description = "Profesjonalne usługi kuśnierskie. Rozsądne ceny. Cennik kuśnierz Konin."
const keywords = ["cennik kożuch", "cena futra"]

const furrieryMakingPrice = ({ data }) => (
    <>
        <SEO
            description={description}
            title="Cennik | Kuśnierz"
            keywords={keywords}
        />
        <div className="prices container">
            <h1 className="title">CENNIK</h1>
            <div className="headerContainer">
                <h2 className="header">Usługi kuśnierskie</h2>
                <p className="desc">Publikowany poniżej cennik ma charakter poglądowy. Podane kwoty nie zawierają cen materiałów oraz użytych dodatków. Dokładna wycena usługi następuje podczas przyjęcia towaru.</p>
            </div>
            <h3>Futro</h3>
            <Prices
                data={dataPrice.kusnierz.futro}
            />
            <h3>Kożuch</h3>
            <Prices
                data={dataPrice.kusnierz.kozuch}
            />
            <h3>Skóry licowe i zamszowe</h3>
            <Prices
                data={dataPrice.kusnierz.licowa}
            />

        </div>
    </>
)


export default furrieryMakingPrice